<template>
    <SKModal
        ref="modal"
        actionButtonsPosition="right"
        :hasAcceptButton="true"
        :acceptCallback="create"
        :title="$t('apps.bannerspaces.form.new.title')"
    >
        <template v-slot:body>
            <div v-if="bannerSpace.type === 'cover'">
                <LabelForm>{{  $t("apps.bannerspaces.form.resortLabel")}}</LabelForm>
                <vs-select class="w-50" v-model="bannerSpace.resort_uuid">
                    <vs-select-item :key="resort.uuid" :value="resort.uuid" :text="resort.name" v-for="resort in resorts"/>
                </vs-select>

                <LabelForm>{{  $t("apps.bannerspaces.form.timeofyearLabel") }}</LabelForm>
                <vs-select class="w-50" v-model="bannerSpace.time_of_year">
                    <vs-select-item :key="timeofyear" :value="timeofyear" :text="timeofyear" v-for="timeofyear in ['winter', 'summer']"/>
                </vs-select>

                <LabelForm>{{  $t("apps.bannerspaces.form.sizeLabel") }}</LabelForm>
                <vs-select class="w-50" v-model="bannerSpace.size_uuid">
                    <vs-select-item :key="size.uuid" :value="size.uuid" :text="size.name" v-for="size in sizes"/>
                </vs-select>
            </div>

            <div v-else>
                <LabelForm>{{  $t("apps.bannerspaces.form.frequencyLabel") }}</LabelForm>
                <vs-input type="number" min="1" class="input-modal w-full border-grey-light" v-model="bannerSpace.frequency" />
            </div>
        </template>
    </SKModal>
</template>
<script>

import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import SKModal from "@/modules/Shared/Components/modal/SKModal";
import loader from "@/modules/Shared/Mixins/loader";
import BannerService from "@/modules/Apps/Services/BannerService";
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'ModalCreateBannerSpace',
    mixins: [loader],
    props: {
        resorts: {
            type: Array,
            required: true
        },
        type: {
            type: String,
            required: true,
            default: 'cover'
        }
    },
    data() {
        return {
            bannerSpace: {},
            sizes: [
                { 'uuid': '1be230ca-f6ad-454e-b177-b19defc9c125', 'name': '4:3' },
                { 'uuid': '4a659972-bf89-47ac-90de-993727eb2b43', 'name': '16:9' },
                { 'uuid': 'ec5b81cc-6519-4ed9-bbc3-637d9fe8e6e6', 'name': '5:2' }
            ],
            interstitialSizeUuid: '2cf12e9c-af53-4496-b4af-dc126c3e5be9',
        }
    },
    components: {
        LabelForm,
        SKModal,
    },
    methods: {
        open() {
            this.initialize()
            this.$refs['modal'].open()
        },
        initialize() {
            this.bannerSpace = {
                type: this.type,
                time_of_year: 'winter',
                size_uuid: this.sizes[0].uuid,
                frequency: 1,
                resort_uuid: this.resorts[0].uuid
            }
        },
        getReference() {
            if (this.bannerSpace.type === 'interstitial') {
                return 'interstitial'
            }

            const resortId = this.resorts.find(resort => resort.uuid === this.bannerSpace.resort_uuid).id
            const timeofyearSuffix = this.bannerSpace.time_of_year === 'summer' ? '_summer' : ''

            return `profile_${resortId}${timeofyearSuffix}`
        },
        async create() {
            await this.loadAndNotify(async () => {
                const body = {
                    application_uuid: this.$route.params.uuid,
                    type: this.bannerSpace.type,
                    reference: this.getReference(),
                }

                if (this.bannerSpace.type === 'interstitial') {
                    body.frequency = this.bannerSpace.frequency
                    body.size_uuid = this.interstitialSizeUuid
                } else {
                    body.time_of_year = this.bannerSpace.time_of_year
                    body.size_uuid = this.bannerSpace.size_uuid
                    body.resort_uuid = this.bannerSpace.resort_uuid
                }

                await BannerService.createBannerspace(uuidv4(), body);

                this.$emit('created')
            })
        }
    },
}
</script>
